import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import ReactPlayer from 'react-player/lazy'

export default ({
  video,
  text
}) => {
  return (
    <FancyShow>
      <Container>
        <Row>
          <Col
            className='py-2'
            offset={{ lg: 2 }}
            col={{ lg: 8 }}
          >
            <ReactPlayer
              url={video}
              width='100%'
              height='422px'
              controls
            />
            <p className='font-italic pt-1 parag-small'>{text}</p>
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}
